import {
    mdiPublish,
    mdiEye,
    mdiExport,
    mdiPen,
    mdiAccountTie,
    mdiFlagVariant,
    mdiDeleteForever,
    mdiChevronUp,
    mdiChevronDown,
    mdiWrench,
    mdiClipboardEditOutline
} from "@mdi/js";
import {
    Card,
    Button,
    Alert,
    Collapse,
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    CardContent,
    Typography,
    useTheme,
    Divider,
    Link
} from "@mui/material";
import { useState } from "react";
import { useWindowSize } from "../../hooks/window";
import { Division, ICollegeEvent, IDualMeet, IDualMeet_DB } from "../../types";
import Icon from "@mdi/react";
import "./DualMeetSummary.css";
import LinkSwitcher from "../SeasonTeamSwitcher/LinkSwitcher";
import useDivision from "../../hooks/divison";
import { useSelector } from "react-redux";
import { ReduxState } from "../../utils/store";

interface SummaryCardProps {
    info: IDualMeet;
    eventInfo?: ICollegeEvent | null;
    score1: number;
    score2: number;
    canManageMeet: boolean;
    canDeleteMeet: boolean;
    correspondingMeet?: IDualMeet | IDualMeet_DB | null;
    editDate: () => void;
    publish: () => void;
    chooseSignature: () => void;
    editAttendance?: () => void;
    exportMeet: () => void;
    configureRefs: () => void;
    viewPINs: () => void;
    endMeet: () => void;
    deleteMeet: () => void;
    repairScore: () => void;
}

const SummaryCardOptions = ({
    info,
    eventInfo,
    score1,
    score2,
    canManageMeet,
    canDeleteMeet,
    correspondingMeet,
    publish,
    chooseSignature,
    editAttendance,
    exportMeet,
    configureRefs,
    viewPINs,
    endMeet,
    deleteMeet,
    repairScore
}: SummaryCardProps) => {
    const isAdmin = useSelector((s: ReduxState) => s.siteAdmin);
    const size = useWindowSize();
    const mobile = size.width < 800 || true;

    // const boxStyle = !mobile ? { position: "absolute", top: 0, right: 0, bottom: 0, left: 0, overflowY: "auto", width: 190 } : {};
    const optionsStyle = { display: "flex", flexWrap: "wrap" };

    const linkSwitcherOptions = [info, correspondingMeet].filter(Boolean).map(l => ({ name: l!.name, id: l!.id }));

    return (
        <Box sx={{ padding: "0 20px 10px 20px" }}>
            <Box sx={optionsStyle}>
                {canManageMeet && !info.published && !eventInfo ? (
                    <Button className="summaryOption" color="success" onClick={publish} startIcon={<Icon path={mdiPublish} size="20px" />}>
                        Publish
                    </Button>
                ) : null}
                {canManageMeet ? (
                    <Button className="summaryOption" onClick={viewPINs} startIcon={<Icon path={mdiEye} size="20px" />}>
                        View PIN
                    </Button>
                ) : null}
                <Button className="summaryOption" onClick={exportMeet} startIcon={<Icon path={mdiExport} size="20px" />}>
                    Export meet
                </Button>
                <Button className="summaryOption" onClick={chooseSignature} startIcon={<Icon path={mdiPen} size="20px" />}>
                    Sign meet
                </Button>
                {editAttendance ? (
                    <Button className="summaryOption" onClick={editAttendance} startIcon={<Icon path={mdiClipboardEditOutline} size="20px" />}>
                        Edit attendance
                    </Button>
                ) : null}
                <Button className="summaryOption" onClick={configureRefs} startIcon={<Icon path={mdiAccountTie} size="20px" />}>
                    View referees
                </Button>
                {canManageMeet && !info?.endedAt ? (
                    <Button
                        className="summaryOption"
                        color="error"
                        onClick={endMeet}
                        startIcon={<Icon path={mdiFlagVariant} size="20px" />}
                    >
                        {score1 + score2 === 27 ? "End" : "Forfeit"} Meet
                    </Button>
                ) : null}
                {canDeleteMeet ? (
                    <Button
                        className="summaryOption"
                        color="error"
                        onClick={deleteMeet}
                        startIcon={<Icon path={mdiDeleteForever} size="20px" />}
                    >
                        Delete meet
                    </Button>
                ) : null}
                {isAdmin ? (
                    <Button
                        className="summaryOption"
                        color="warning"
                        onClick={repairScore}
                        startIcon={<Icon path={mdiWrench} size="20px" />}
                    >
                        Repair meet score
                    </Button>
                ) : null}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {Boolean(correspondingMeet) && <LinkSwitcher route="/meet" selectedName={info.name} options={linkSwitcherOptions} />}
            </Box>
        </Box>
    );
};

const DualMeetSummary = (props: SummaryCardProps) => {
    const { info, score1, score2, canManageMeet, eventInfo, editDate, publish } = props;
    const size = useWindowSize();
    const theme = useTheme();
    const division = useDivision();
    const [drawerOpen, setDrawerOpen] = useState(true);

    const meetStarted = Date.now() > info.startedAt;
    const mobile = size.width < 800 || true;

    const publishedStr = (() => {
        if (eventInfo) {
            if (meetStarted) return "This meet's event has started, but it has not been published to public viewers.";
            else return "This meet's event has not yet been published to public viewers.";
        } else {
            if (meetStarted) return "This meet has started, but it has not been published to public viewers.";
            else return "This meet has not yet been published to public viewers.";
        }
    })();

    const autoGeneratedName = `${info.team1.name} (${division === Division.NCAA ? "A" : "H"}) vs. ${info.team2.name} (${division === Division.NCAA ? "B" : "A"})`;
    const nameIsAutoGenerated = info.name === autoGeneratedName;

    return (
        <Card
            sx={{
                maxWidth: 1000,
                width: "90vw",
                margin: "30px auto 20px auto",
                display: mobile ? "block" : "flex"
            }}
        >
            <CardContent sx={{ padding: 2, flexGrow: 1 }}>
                {!nameIsAutoGenerated && (
                    <Typography component="h1" variant="h4" sx={{ margin: "10px 0" }}>
                        {info.name}
                    </Typography>
                )}
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}></Box>
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box className="summaryNameColumn">
                        <Typography variant="overline">Home</Typography>
                        <a
                            href={info.team1.id && `/team/${info.team1.id}`}
                            style={{ color: theme.palette.text.primary }}
                            className={info.team1.id ? "dualMeetNameLink" : "dualMeetNameLinkDisabled"}
                        >
                            <Typography component="h1" variant={nameIsAutoGenerated ? "h4" : "h5"} color="inherit" gutterBottom>
                                {info.team1.name}
                            </Typography>
                        </a>
                    </Box>
                    <Divider orientation="vertical" flexItem>
                        vs.
                    </Divider>
                    <Box className="summaryNameColumn">
                        <Typography variant="overline">Away</Typography>
                        <a
                            href={info.team2.id && `/team/${info.team2.id}`}
                            style={{ color: theme.palette.text.primary }}
                            className={info.team2.id ? "dualMeetNameLink" : "dualMeetNameLinkDisabled"}
                        >
                            <Typography component="h1" variant={nameIsAutoGenerated ? "h4" : "h5"} color="inherit" gutterBottom>
                                {info.team2.name}
                            </Typography>
                        </a>
                    </Box>
                </Box>
                {eventInfo && (
                    <Typography variant="h6" color="text.secondary">
                        Dual meet within <a href={`/eventInfo/${eventInfo.id}`}>{eventInfo.name}</a>
                    </Typography>
                )}
                <Typography variant="h6" color="text.secondary" sx={{ alignItems: "center" }}>
                    {info.startedAt ? new Date(info.startedAt).toDateString() : "No start date"}
                    {info.startedAt ? ` ${new Date(info.startedAt).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}` : ""}
                    {canManageMeet ? (
                        <Button sx={{ marginLeft: "5px" }} onClick={editDate}>
                            Edit
                        </Button>
                    ) : null}
                </Typography>
                <Typography variant="h5" color="text.primary">
                    Score: <strong>{score1}</strong> - <strong>{score2}</strong>
                </Typography>
                {!info.published ? (
                    <Alert
                        variant="filled"
                        severity={meetStarted ? "error" : "warning"}
                        action={
                            <Button sx={{ color: "white" }} onClick={() => (mobile ? setDrawerOpen(true) : publish())}>
                                {eventInfo ? "View event" : mobile ? "Settings" : "Publish"}
                            </Button>
                        }
                        sx={{ maxWidth: "700px", margin: "5px auto 0 auto", alignItems: "center" }}
                    >
                        {publishedStr}
                    </Alert>
                ) : null}
                {mobile ? (
                    <Button
                        sx={{ marginTop: "10px" }}
                        onClick={() => setDrawerOpen(u => !u)}
                        startIcon={<Icon path={drawerOpen ? mdiChevronUp : mdiChevronDown} size="20px" />}
                    >
                        {drawerOpen ? "Close" : "Open"} settings
                    </Button>
                ) : null}
            </CardContent>
            {mobile ? (
                <Collapse in={drawerOpen} orientation="vertical">
                    <SummaryCardOptions {...props} />
                </Collapse>
            ) : (
                <Box
                    sx={{
                        position: "relative",
                        flexShrink: 0,
                        width: 190,
                        borderLeft: "1px solid",
                        borderLeftColor: theme.palette.divider
                    }}
                >
                    <SummaryCardOptions {...props} />
                </Box>
            )}
        </Card>
    );
};

export default DualMeetSummary;
