import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CommonLoading } from "../../../components/Loading/Loading";

import TbTPage from "../../../components/TbTPage/TbTPage";
import DualMeetInfoComponent from "../../../components/DualMeetInfo";
import SeasonSwitcher from "../../../components/SeasonTeamSwitcher/SeasonSwitcher";
import { ITeam, IExistingFencer, Weapon, IDualMeet, IDualMeetBout, BoutSide, UserFlag, IDualMeet_DB, IOrganization } from "../../../types";
import { meetScoreFromBouts, dualMeetSquadScores, boutWinner, meetScoreByWeapon, rosterMapFromTeam } from "../../../utils/helpers";
import { ReduxState } from "../../../utils/store";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";

import "./TeamDetail.css";
import { useWindowSize } from "../../../hooks/window";
import { Skeleton, TableBody, TableCell, TableHead, TableRow, Table, MenuItem, Link, Tooltip, Box } from "@mui/material";
import useDatabase from "../../../hooks/database";
import { NCAADivisions, NCAAMensConferences, NCAAWomensConferences } from "../../../utils/ncaaConference";
import StyledDropdown from "../../../components/StyledDropdown/StyledDropdown";
import ErrorPage from "../NotFound/NotFound";
import { DBResult, isSuccess } from "../../../utils/database";
import { ERROR_TEAM_DOES_NOT_EXIST } from "../../../utils/constants";
import RosterAutoUpdateDialog from "../../../components/RosterAutoUpdateDialog";

interface FencerListItem {
    fencer: IExistingFencer;
    role: { home: number; away: number; power?: number };
    records: Record<string, { wins: number; losses: number }>;
}

const FencerListItem = ({ fencer, role, records }: FencerListItem) => {
    return (
        <ListItemButton sx={{ width: "100%" }} href={`/fencer/${fencer.id}`}>
            <div className="teamDetailRosterMemberName">
                {fencer.firstName} {fencer.lastName} - {fencer.gradYear}
            </div>
            {role.power && (
                <Tooltip title="Power rating" placement="top" disableInteractive>
                    <div className="teamDetailRosterMemberPower">{role.power}</div>
                </Tooltip>
            )}
            <div className="teamDetailRosterMemberRecord">
                {records[fencer.id]?.wins || 0}-{records[fencer.id]?.losses || 0}
            </div>
        </ListItemButton>
    );
};

export default function TeamDetail() {
    const { id } = useParams<{ id: string }>();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const rosterSeason = useSelector((s: ReduxState) => s.season);

    const [orgTeams, setOrgTeams] = useState<ITeam[]>([]);
    const [orgData, setOrgData] = useState<IOrganization | null>(null);
    const [processedRoster, setProcessedRoster] = useState<
        Record<Weapon, Map<IExistingFencer, { home: number; away: number; power?: number }>>
    >({ Sabre: new Map(), Foil: new Map(), Epee: new Map() });
    const [dualMeets, setDualMeets] = useState<IDualMeet_DB[]>([]);
    const [dualMeetsLoaded, setDualMeetsLoaded] = useState(false);
    const [scoresForMeets, setScoresForMeets] = useState<Record<string, [number, number]>>({});
    const [squadScores, setSquadScores] = useState<Record<Weapon, { wins: number; losses: number }>>({
        Sabre: { wins: 0, losses: 0 },
        Foil: { wins: 0, losses: 0 },
        Epee: { wins: 0, losses: 0 }
    });
    // const [boutsCache, setBoutsCache] = useState<Record<string, IDualMeetBout>>({});
    const [fencerRecords, setFencerRecords] = useState<Record<Weapon, Record<string, { wins: number; losses: number }>>>({
        Sabre: {},
        Foil: {},
        Epee: {}
    });

    const [criticalError, setCriticalError] = useState("");

    const teamData = orgTeams.find(l => l.id === id) || null;

    const size = useWindowSize();
    const DB = useDatabase();

    const handleTeam = async (result: DBResult<ITeam>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        const { data } = result;
        DB.getOrganizationFromTeam(data.id).then(orgResult => {
            if (orgResult.status === "fail") return setCriticalError(orgResult.data);
            const org = orgResult.data;
            setOrgData(org);
            const idArr: string[] = [];
            if (org.boysTeam) idArr.push(org.boysTeam);
            if (org.girlsTeam) idArr.push(org.girlsTeam);
            if (org.boysJVTeam) idArr.push(org.boysJVTeam);
            if (org.girlsJVTeam) idArr.push(org.girlsJVTeam);
            Promise.all(idArr.map(l => DB.getTeam(l))).then(s => setOrgTeams(s.filter(isSuccess).map(l => l.data) as ITeam[]));
        });
        // setAdministratingTeams(await Promise.all(userInfo.teams.map(l => DB.getTeam(l))));
    };

    useEffect(() => {
        DB.getTeam(id, { listener: handleTeam });

        return () => {
            DB.stopListeningTeam(id, handleTeam);
        };
    }, []);

    useEffect(() => {
        if (!teamData) return;
        Promise.all(teamData.fencers.map(l => DB.getFencerRecord(l))).then(records => {
            const res: typeof fencerRecords = {
                Sabre: {},
                Foil: {},
                Epee: {}
            };

            for (const fencerRecordResult of records) {
                if (fencerRecordResult.status === "fail") continue;
                const fencerRecord = fencerRecordResult.data;
                const bouts = Object.values(fencerRecord.bouts);
                for (const record of bouts) {
                    if (record.season !== rosterSeason) continue;
                    const targetObj = res[record.weapon];
                    if (fencerRecord.id in targetObj) {
                        targetObj[fencerRecord.id].wins += Number(record.won);
                        targetObj[fencerRecord.id].losses += Number(record.lost);
                    } else {
                        targetObj[fencerRecord.id] = { wins: Number(record.won), losses: Number(record.lost) };
                    }
                }
            }

            setFencerRecords(res);
        });
    }, [teamData, rosterSeason]);

    useEffect(() => {
        let active = true;
        if (teamData) {
            rosterMapFromTeam(DB, teamData, rosterSeason).then(roster => setProcessedRoster(roster));
        }
        (async () => {
            const meetIDs = teamData?.dualMeets?.[rosterSeason] || [];

            // If there have been meets, process them
            const meetResults = await Promise.all(meetIDs.map(l => DB.getDualMeetRaw(l)));
            const meets = meetResults
                .filter(isSuccess)
                .map(l => l.data)
                .filter(value => value !== null && value !== undefined && value.id !== undefined);
            setDualMeets(meets);
            setDualMeetsLoaded(true);

            const bouts: IDualMeetBout[][] = [];
            for (const meet of meets) {
                const results = await Promise.all(meet.bouts.map(l => DB.getBout(l.id)));
                const filtered = results.filter(isSuccess).map(l => l.data);
                bouts.push(filtered);
            }

            // Scores for each meet
            const scores = bouts.map(meetScoreFromBouts);
            const scoresObj = {};
            for (let i = 0; i < scores.length; i++) {
                scoresObj[meets[i].id] = scores[i];
            }
            setScoresForMeets(scoresObj);

            // Squad-level scores for each meet
            const squadScores = bouts.map(meetScoreByWeapon);
            // Squad-level scores for each meet, but for the team we are currently viewing
            const squadScoresObj = {
                Sabre: { wins: 0, losses: 0 },
                Foil: { wins: 0, losses: 0 },
                Epee: { wins: 0, losses: 0 }
            };
            for (let i = 0; i < squadScores.length; i++) {
                const score = squadScores[i];
                const meet = meets[i];
                for (const weapon in score) {
                    if (meet.team1ID === id) {
                        if (score[weapon][0] > score[weapon][1]) {
                            squadScoresObj[weapon].wins++;
                        } else if (score[weapon][0] < score[weapon][1]) {
                            squadScoresObj[weapon].losses++;
                        }
                    } else if (meet.team2ID === id) {
                        if (score[weapon][1] > score[weapon][0]) {
                            squadScoresObj[weapon].wins++;
                        } else if (score[weapon][1] < score[weapon][0]) {
                            squadScoresObj[weapon].losses++;
                        }
                    }
                }
            }
            setSquadScores(squadScoresObj);
            console.timeEnd("Loading scores");
        })();

        return () => {
            active = false;
        };
    }, [teamData, rosterSeason]);

    if (criticalError === ERROR_TEAM_DOES_NOT_EXIST) {
        return <ErrorPage code={404} message="The requested team could not be found." />;
    } else if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    if (!teamData) {
        return (
            <TbTPage>
                <CommonLoading color="#714FCA" size="large" />
            </TbTPage>
        );
    }

    const sabreEntries = Array.from(processedRoster.Sabre.entries());
    const foilEntries = Array.from(processedRoster.Foil.entries());
    const epeeEntries = Array.from(processedRoster.Epee.entries());

    const overallScore = (() => {
        let wins = 0;
        let losses = 0;

        if (!dualMeets.length || !Object.keys(scoresForMeets).length) return [0, 0];

        for (let i = 0; i < dualMeets.length; i++) {
            const dualMeet = dualMeets[i];
            const score = scoresForMeets[dualMeet.id];

            if (!score) continue;

            const midnightTomorrow = new Date(dualMeet.startedAt).setHours(0, 0, 0, 0) + 86400000;
            const ended = dualMeet.endedAt || Date.now() > midnightTomorrow;

            if (score[0] + score[1] !== 27 && !ended) continue;

            if (dualMeet.team1ID === id) {
                if (score[0] > score[1]) {
                    wins++;
                } else if (score[1] > score[0]) {
                    losses++;
                }
            } else if (dualMeet.team2ID === id) {
                if (score[1] > score[0]) {
                    wins++;
                } else if (score[0] > score[1]) {
                    losses++;
                }
            }
        }

        return [wins, losses];
    })();

    const highestSquadWinCount = Math.max(...Object.values(squadScores).map(l => l.wins));
    const topSquads = Object.keys(squadScores).filter(l => squadScores[l].wins === highestSquadWinCount);

    const allowedToManageTeam =
        (userInfo?.flags || 0) & UserFlag.UberAdmin ||
        userInfo?.teams?.includes(id) ||
        (teamData.managers || []).includes(userInfo?.id || "adf");

    const conference = teamData.conference
        ? teamData.name.toLowerCase().includes("women")
            ? [...NCAAWomensConferences.filter(c => teamData.conference!.includes(c.id)).map(c => c.name)]
            : [...NCAAMensConferences.filter(c => teamData.conference!.includes(c.id)).map(c => c.name)]
        : [];

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: size.width < 800 ? "column" : "row"
                }}
            >
                {orgData?.logoPath && (
                    <img
                        style={{
                            objectFit: "cover",
                            width: 128,
                            height: 128,
                            display: "block",
                            marginRight: size.width < 800 ? 0 : 32,
                            marginBottom: size.width < 800 ? 32 : 0
                        }}
                        src={`https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(orgData.logoPath)}?alt=media`}
                    />
                )}
                <div>
                    <h1 style={{ fontWeight: "500", fontFamily: "Lexend Deca" }}>{teamData.name}</h1>
                    <h3>{NCAADivisions.find(l => l.id === teamData.region)?.name || ""}</h3>
                    <h5>{conference && conference.join(", ")}</h5>
                </div>
            </div>
            <Box
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    flexDirection: size.width < 800 ? "column" : "row",
                    gap: "8px",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    paddingLeft: "25px"
                }}
            >
                <StyledDropdown displayText={teamData.name}>
                    {orgTeams
                        .filter(l => l.id !== id)
                        .map(team => (
                            <Link href={`/team/${team.id}`} key={`switchTeam${team.id}`}>
                                <MenuItem>{team.name}</MenuItem>
                            </Link>
                        ))}
                </StyledDropdown>
                <SeasonSwitcher teamID={id} />
            </Box>
            <Card sx={{ maxWidth: 1100, width: "90%", margin: "0 auto" }}>
                <CardHeader title="Team Stats" />
                <CardContent sx={{ padding: "0 !important" }}>
                    <Table size="small">
                        <colgroup>
                            <col style={{ width: "34%" }} />
                            <col style={{ width: "33%" }} />
                            <col style={{ width: "33%" }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell size="medium" sx={{ fontSize: "150%" }}>
                                    Wins
                                </TableCell>
                                <TableCell size="medium" sx={{ fontSize: "150%" }}>
                                    Losses
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover>
                                <TableCell sx={{ fontSize: "150%" }}>Team</TableCell>
                                <TableCell sx={{ fontSize: "150%" }}>{overallScore[0]}</TableCell>
                                <TableCell sx={{ fontSize: "150%" }}>{overallScore[1]}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Sabre</TableCell>
                                <TableCell>{squadScores.Sabre.wins}</TableCell>
                                <TableCell>{squadScores.Sabre.losses}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Foil</TableCell>
                                <TableCell>{squadScores.Foil.wins}</TableCell>
                                <TableCell>{squadScores.Foil.losses}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Epee</TableCell>
                                <TableCell>{squadScores.Epee.wins}</TableCell>
                                <TableCell>{squadScores.Epee.losses}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize: "150%" }}>{topSquads.length > 1 ? "Top Squads" : "Top Squad"}</TableCell>
                                <TableCell
                                    sx={{
                                        fontSize: "150%",
                                        textAlign: "center"
                                    }}
                                    colSpan={2}
                                >
                                    {topSquads.join(", ")}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <main className="teamDetailContentDiv">
                <div className="teamDetailRosterSection">
                    <Card className="teamDetailRoster">
                        <CardHeader
                            sx={{
                                backgroundColor: theme => theme.palette.primary.light,
                                position: "relative",
                                height: "60px"
                            }}
                            action={
                                allowedToManageTeam ? (
                                    <Button
                                        href={`/rosterEditor/${id}`}
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            position: "absolute",
                                            top: 12,
                                            right: 12
                                        }}
                                    >
                                        {size.width < 800 ? "Edit" : "Edit roster"}
                                    </Button>
                                ) : null
                            }
                            title="Roster"
                        />
                        <CardContent
                            sx={{
                                padding: "0 !important",
                                overflowY: "auto",
                                maxHeight: "540px"
                            }}
                        >
                            {processedRoster.Sabre.size || processedRoster.Foil.size || processedRoster.Epee.size ? (
                                <List
                                    sx={{
                                        overflowY: "auto",
                                        padding: "0 !important"
                                    }}
                                >
                                    <ListSubheader>Sabre</ListSubheader>
                                    {sabreEntries
                                        .sort((a, b) => (a[0].gradYear || 0) - (b[0].gradYear || 0))
                                        .map(([l, role]) => (
                                            <FencerListItem fencer={l} role={role} records={fencerRecords.Sabre} key={`tdrmbrs${l.id}`} />
                                        ))}
                                    <Divider />
                                    <ListSubheader>Foil</ListSubheader>
                                    {foilEntries
                                        .sort((a, b) => (a[0].gradYear || 0) - (b[0].gradYear || 0))
                                        .map(([l, role]) => (
                                            <FencerListItem fencer={l} role={role} records={fencerRecords.Foil} key={`tdrmbrf${l.id}`} />
                                        ))}
                                    <Divider />
                                    <ListSubheader>Epee</ListSubheader>
                                    {epeeEntries
                                        .sort((a, b) => (a[0].gradYear || 0) - (b[0].gradYear || 0))
                                        .map(([l, role]) => (
                                            <FencerListItem fencer={l} role={role} records={fencerRecords.Epee} key={`tdrmbre${l.id}`} />
                                        ))}
                                </List>
                            ) : (
                                <Typography sx={{ padding: "15px" }}>
                                    This team doesn't seem to have added any fencers for their roster yet.
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </div>
                <div
                    className="teamDetailMeetsSection"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "black"
                    }}
                >
                    <h3>Dual meets for {teamData.name}</h3>
                    <div className="teamDetailMeetsList">
                        {dualMeetsLoaded ? (
                            Object.keys(teamData.dualMeets[rosterSeason] || {}).length === 0 ? (
                                <h4
                                    style={{
                                        color: "gray",
                                        marginTop: "25px",
                                        fontSize: 18
                                    }}
                                >
                                    No dual meets yet!
                                </h4>
                            ) : (
                                <div
                                    className="teamDetailMeets"
                                    style={{
                                        maxWidth: 450,
                                        textAlign: "center",
                                        margin: "auto",
                                        maxHeight: "100%",
                                        overflowY: "auto"
                                    }}
                                >
                                    {dualMeets.length === 0
                                        ? Object.keys(teamData!.dualMeets[rosterSeason || ""] || {}).map(l => (
                                              <Skeleton
                                                  key={`skeletongoadfm${l}`}
                                                  sx={{ marginBottom: "20px" }}
                                                  width={450}
                                                  height={190}
                                                  variant="rectangular"
                                              />
                                          ))
                                        : dualMeets
                                              .sort((a, b) => b.startedAt - a.startedAt)
                                              .map(data => <DualMeetInfoComponent data={data} key={`dualMadsfi${data.id}`} />)}
                                </div>
                            )
                        ) : (
                            <div
                                style={{
                                    position: "relative",
                                    height: "600px"
                                }}
                            >
                                <CommonLoading color="#714FCA" size="large" />
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <RosterAutoUpdateDialog />
        </TbTPage>
    );
}
